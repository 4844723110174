import cn from 'classnames';
import { useState } from 'react';
// Types
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import { ButtonSize } from 'ui2/Button/Button';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import {
  GetMediaPost_getMediaPost_ImagePost_store,
  GetMediaPost_getMediaPost_VideoPost_store,
} from 'api/mediaPost/types/GetMediaPost';
// UI
import Modal from 'ui/Modal/Modal';
import Icon from 'ui/Icon/Icon';
// UI2
import Button from 'ui2/Button/Button';
// Components
import FollowButton from 'components/FollowButton/FollowButton';
import HubSpotForm from 'components/HubspotForm/HubSpotForm';
// Styles
import styles from './AthleteMenu.module.scss';

type AthleteMenuProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  size: ButtonSize;
};

const AthleteMenu = ({ store, size }: AthleteMenuProps) => {
  const [isFollowed, setIsFollowed] = useState<boolean>(false);
  const [isHubspotModalOpen, setIsHubSpotModalOpen] = useState<boolean>(false);

  const openHubspotModal = () => {
    setIsHubSpotModalOpen(true);
  };
  const closeHubspotModal = () => {
    setIsHubSpotModalOpen(false);
  };
  return (
    <div className={styles.root}>
      <div className={styles.buttonsWrapper}>
        <div className={styles.actionButtonWrapper}>
          <FollowButton
            className={cn(styles.actionButton, {
              [styles.textColorGold]: isFollowed,
            })}
            store={store}
            startIcon="plus"
            unfollowText="Following"
            unfollowStartIcon="check"
            followColor="harvest-gold"
            unFollowColor="white"
            onChange={setIsFollowed}
            xs={size}
          />
        </div>
        <div className={styles.actionButtonWrapper}>
          <Button
            color={isFollowed ? 'harvest-gold' : 'white'}
            className={cn(
              styles.actionButton,
              styles.actionButtonGrayBorder,
              styles.hubSpotButton
            )}
            onClick={openHubspotModal}
            xs="xSmall"
            l="default"
          >
            <Icon name="v2-letter" className={styles.buttonIcon} />
            Book me
          </Button>
        </div>
      </div>

      <Modal
        size="medium"
        open={isHubspotModalOpen}
        onClose={closeHubspotModal}
      >
        <HubSpotForm />
      </Modal>
    </div>
  );
};

export default AthleteMenu;
