import { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
// Api
import { IS_USER_FOLLOWING_STORE } from 'api/follow/queries';
import { FOLLOW_STORE, UNFOLLOW_STORE } from 'api/follow/mutations';
// Hooks
import { useAppContext, useGetCurrUser, useRefetchOnUserLogIn } from 'hooks';
// Types
import {
  IsUserFollowingStore,
  IsUserFollowingStoreVariables,
} from 'api/follow/types/IsUserFollowingStore';
import {
  FollowStores,
  FollowStoresVariables,
} from 'api/follow/types/FollowStores';
import {
  UnfollowStores,
  UnfollowStoresVariables,
} from 'api/follow/types/UnfollowStores';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
// UI
import Icon, { IconNames } from 'ui/Icon/Icon';
// UI2
import Button, { ButtonColor, ButtonSize } from 'ui2/Button/Button';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './FollowButton.module.scss';

type FollowButtonProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  startIcon?: IconNames;
  unfollowStartIcon?: IconNames;
  unfollowText: string;
  className: string;
  followColor: ButtonColor;
  unFollowColor: ButtonColor;
  xs?: ButtonSize;
  onChange?: (isFollowing: boolean) => void;
};

const FollowButton = ({
  store,
  startIcon,
  unfollowStartIcon,
  unfollowText,
  className,
  followColor,
  unFollowColor,
  xs = 'default',
  onChange,
}: FollowButtonProps) => {
  const { data } = useGetCurrUser();
  const isLoggedIn = Boolean(data?.me?.id);
  const { setLoginModalVisibility } = useAppContext();

  const { data: followStatus, refetch } = useQuery<
    IsUserFollowingStore,
    IsUserFollowingStoreVariables
  >(IS_USER_FOLLOWING_STORE, {
    variables: {
      input: {
        storeId: store.id,
      },
    },
    skip: !isLoggedIn,
    fetchPolicy: 'network-only',
  });

  useRefetchOnUserLogIn({ refetch });

  const isFollowing = Boolean(followStatus?.isUserFollowingStore.isFollowing);

  const [followStore, { loading: followLoading }] = useMutation<
    FollowStores,
    FollowStoresVariables
  >(FOLLOW_STORE);

  const [unfollowStore, { loading: unfollowLoading }] = useMutation<
    UnfollowStores,
    UnfollowStoresVariables
  >(UNFOLLOW_STORE);

  const handleFollowStore = async () => {
    if (isLoggedIn) {
      try {
        await followStore({
          variables: {
            input: {
              storeIds: [store.id],
            },
          },
          refetchQueries: [
            {
              query: IS_USER_FOLLOWING_STORE,
              variables: {
                input: {
                  storeId: store.id,
                },
              },
            },
          ],
        });
      } catch (error) {
        showToast({
          message: (error as Error).message || 'Error following the store',
          type: 'error',
        });
      }
    } else {
      setLoginModalVisibility(true);
    }
  };

  useEffect(() => {
    onChange && onChange(isFollowing);
  }, [isFollowing, onChange]);

  const handleUnFollowStore = async () => {
    try {
      await unfollowStore({
        variables: {
          input: {
            storeIds: [store.id],
          },
        },
        refetchQueries: [
          {
            query: IS_USER_FOLLOWING_STORE,
            variables: {
              input: {
                storeId: store.id,
              },
            },
          },
        ],
      });
    } catch (error) {
      showToast({
        message: (error as Error).message || 'Error unfollowing the store',
        type: 'error',
      });
    }
  };

  return (
    <>
      {!isFollowing && (
        <Button
          loading={followLoading}
          color={followColor}
          className={className}
          onClick={handleFollowStore}
          xs={xs}
        >
          {startIcon && <Icon name={startIcon} className={styles.buttonIcon} />}
          Follow
        </Button>
      )}
      {isFollowing && (
        <Button
          loading={unfollowLoading}
          color={unFollowColor}
          className={className}
          onClick={handleUnFollowStore}
          xs={xs}
        >
          {unfollowStartIcon && (
            <Icon name={unfollowStartIcon} className={styles.buttonIcon} />
          )}
          {unfollowText}
        </Button>
      )}
    </>
  );
};
export default FollowButton;
