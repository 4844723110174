import { useState } from 'react';
import cn from 'classnames';
// Types
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
// UI
import Modal from 'ui/Modal/Modal';
import Icon from 'ui/Icon/Icon';
import Image from 'ui/Image/Image';
// UI2
import Button from 'ui2/Button/Button';
// Components
import FollowButton from 'components/FollowButton/FollowButton';
import HubSpotForm from 'components/HubspotForm/HubSpotForm';
// Styles
import styles from './EmptyPage.module.scss';

type NavTabType = 'home' | 'shop' | 'channel';

type EmptyPageProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  navTabType: NavTabType;
};

const EmptyPage = ({ store, navTabType }: EmptyPageProps) => {
  const [isHubspotModalOpen, setIsHubSpotModalOpen] = useState<boolean>(false);

  const openHubspotModal = () => {
    setIsHubSpotModalOpen(true);
  };
  const closeHubspotModal = () => {
    setIsHubSpotModalOpen(false);
  };

  const avatarUrl = store.storeDetails?.avatarURL || '';
  const storeName = store.storeDetails?.storeName || '';

  const isHome = navTabType === 'home';

  const contentHeading = isHome
    ? 'Book or Sponsor me'
    : navTabType === 'shop'
    ? 'Products Coming Soon'
    : 'Streams Coming Soon';

  const description = isHome
    ? 'I’m ready to work with your brand, book me today!'
    : navTabType === 'shop'
    ? 'Follow to get notified about New Products, Merch Drops, and Experiences'
    : 'Follow to get notified when I’ll Go Live next!';

  const renderFollowMeButton = () => {
    return (
      <div className={styles.buttonWrapper}>
        <FollowButton
          className={styles.button}
          store={store}
          startIcon="plus"
          unfollowText="Following"
          unfollowStartIcon="check"
          followColor="harvest-gold"
          unFollowColor="harvest-gold"
          xs="xSmall"
        />
      </div>
    );
  };

  const renderHubSpotButton = () => {
    return (
      <div className={styles.buttonWrapper}>
        <Button
          color="harvest-gold"
          className={styles.button}
          onClick={openHubspotModal}
          xs="xSmall"
          l="default"
        >
          <Icon name="v2-letter" className={styles.buttonIcon} />
          Book me
        </Button>
        <Modal
          size="medium"
          open={isHubspotModalOpen}
          onClose={closeHubspotModal}
        >
          <HubSpotForm />
        </Modal>
      </div>
    );
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.homeBackground]: isHome,
      })}
    >
      <div className={styles.imageWrapper}>
        <Image className={styles.image} src={avatarUrl} alt={storeName} />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentHeading}>{contentHeading}</div>
        <div className={styles.description}>{description}</div>
        {isHome ? renderHubSpotButton() : renderFollowMeButton()}
      </div>
    </div>
  );
};
export default EmptyPage;
