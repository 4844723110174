import { gql } from '@apollo/client';

export const IS_USER_FOLLOWING_STORE = gql`
  query IsUserFollowingStore($input: IsUserFollowingStoreInput!) {
    isUserFollowingStore(input: $input) {
      isFollowing
    }
  }
`;

export const GET_NUMBER_OF_FOLLOWERS = gql`
  query GetNumberOfFollowers($input: GetNumberOfFollowersInput!) {
    getNumberOfFollowers(input: $input) {
      count
    }
  }
`;
