import cn from 'classnames';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui2
import BackgroundTitle from 'ui2/BackgroundTitle/BackgroundTitle';

import styles from './TopSection.module.scss';

type SectionProps = {
  backgroundTitle?: string;
  className?: string;
  backgroundTitleColor?: 'black' | 'gold' | 'white';
};

const TopSection: React.FC<SectionProps> = ({
  backgroundTitle,
  className,
  children,
  backgroundTitleColor,
}) => {
  return (
    <section className={cn(styles.root, className)}>
      {backgroundTitle && (
        <BackgroundTitle
          content={backgroundTitle}
          className={styles.backgroundTitle}
          color={backgroundTitleColor}
        />
      )}

      <ResolutionWrapper>
        <div className={styles.body}>{children}</div>
      </ResolutionWrapper>
    </section>
  );
};

export default TopSection;
