import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
// Hooks
import { useGetStreams } from 'hooks';
// Types
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import { StreamStatus } from 'api/graphql-global-types';
// Components
import Tab from './Tab/Tab';
import EmptyPage from './EmptyPage/EmptyPage';
// Styles
import styles from './TabsNavigation.module.scss';

export type TabOptions = {
  name: string;
  url: string;
};

type ExtendedTabOptions = TabOptions & {
  path: string;
};

type TabsNavigationProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  pending?: boolean;
};

const TabsNavigation = ({ store, pending }: TabsNavigationProps) => {
  const { asPath, query, pathname } = useRouter();
  const { athleteSlug } = query;

  // split the page address to get current address.
  const pageAddress =
    pathname.split('/').pop() === '[athleteSlug]'
      ? ''
      : pathname.split('/').pop() || '';

  const baseProfilePath = '/' + athleteSlug;

  const shopTabList: ExtendedTabOptions[] = [];

  if (store.hasMerch) {
    shopTabList.push({
      name: 'merch',
      path: 'merch',
      url: baseProfilePath + '/merch',
    });
  }

  if (store.hasMemorabilia) {
    shopTabList.push({
      name: 'memorabilia',
      path: 'memorabilia',
      url: baseProfilePath + '/memorabilia',
    });
  }

  if (store.hasPureProducts) {
    shopTabList.push({
      name: 'products',
      path: 'products',
      url: baseProfilePath + '/products',
    });
  }

  if (store.hasExperiences) {
    shopTabList.push({
      name: 'experiences',
      path: 'experiences',
      url: baseProfilePath + '/experiences',
    });
  }

  const contentTabList: ExtendedTabOptions[] = [];

  if (store.hasStreams) {
    contentTabList.push({
      name: 'streams',
      path: 'streams',
      url: baseProfilePath + '/streams',
    });
  }

  if (store.hasMediaPost) {
    contentTabList.push({
      name: 'media feed',
      path: 'media',
      url: baseProfilePath + '/media',
    });
  }

  if (store.hasArticle) {
    contentTabList.push({
      name: 'news',
      path: 'news',
      url: baseProfilePath + '/news',
    });
  }

  // using pageAddress to detect if selected option is home, shop or channel
  const isHome = pageAddress === '';
  const isShop =
    !!shopTabList.find((tab) => tab.path === pageAddress) ||
    pageAddress === 'merch'; // in case of an empty page
  const isContent =
    !!contentTabList.find((tab) => tab.path === pageAddress) ||
    pageAddress === 'streams' || // in case of an empty page
    pageAddress === '[mediaPostSlug]' || // in case of a media feed item view
    pageAddress === '[streamSlug]' || // in case of a stream item view
    pageAddress === '[newsSlug]'; // in case of a news item view

  const skipGetStreams =
    !store.hasActiveStreams || isShop || pageAddress === 'media';

  const { data: streamsData } = useGetStreams({
    variables: {
      input: {
        storeId: store?.id,
        streamStatus: [
          StreamStatus.Active,
          StreamStatus.Paused,
          StreamStatus.Interrupted,
          StreamStatus.Scheduled,
          StreamStatus.Ended,
        ],
      },
      withSponsors: true,
    },
    skip: skipGetStreams,
  });

  const activeStream = streamsData?.streamsV2.entities?.find(
    (stream) => stream.streamStatus === StreamStatus.Active
  );

  const hasShop = shopTabList.length;
  const hasContent = contentTabList.length;
  const hasHome = hasShop || hasContent || store.hasAma;

  const shopUrl = hasShop ? shopTabList[0].url : baseProfilePath + '/merch'; // second option is for empty shop, it will display the empty page component
  const channelUrl = hasContent
    ? contentTabList[0].url
    : baseProfilePath + '/streams'; // second option is for empty channel, it will display the empty page component

  const isEmptyHome = baseProfilePath === asPath && !hasHome;
  const isEmptyShop = pageAddress === 'merch' && !hasShop;
  const isEmptyChannel = pageAddress === 'streams' && !hasContent;

  const showEmptyPage = isEmptyHome || isEmptyShop || isEmptyChannel; // flag that indicates if there is an empty component or not
  const emptyPageType = isEmptyHome ? 'home' : isEmptyShop ? 'shop' : 'channel'; // display message meant for specific empty component

  const showSeparator = activeStream || isShop || isContent || showEmptyPage; // this is the line that separates base options from suboptions and empty component

  if (pending) {
    return (
      <>
        <div className={styles.navGroup}>
          <div
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isHome,
            })}
          >
            <Link href={baseProfilePath}>home</Link>
          </div>
        </div>
        {isEmptyHome && <EmptyPage store={store} navTabType={'home'} />}
      </>
    );
  }

  return (
    <>
      <div className={styles.navGroup}>
        <div
          className={cn(styles.navOption, {
            [styles.highlightedNavOption]: isHome,
          })}
        >
          <Link href={baseProfilePath}>home</Link>
        </div>
        <div
          className={cn(styles.navOption, {
            [styles.highlightedNavOption]: isShop,
          })}
        >
          <Link href={shopUrl}>shop</Link>
        </div>
        <div
          className={cn(styles.navOption, {
            [styles.highlightedNavOption]: isContent,
          })}
        >
          <Link href={channelUrl}>content</Link>
        </div>
      </div>
      <div className={cn({ [styles.navSeparator]: showSeparator })}></div>
      <div className={styles.tabsSection}>
        <div
          className={cn(styles.hidden, {
            [styles.showTabs]: isShop && hasShop,
            [styles.allowOverflow]: isShop,
          })}
        >
          {shopTabList.map(({ name, url }) => (
            <Tab key={name} name={name} url={url} pageAddress={pageAddress} />
          ))}
        </div>
      </div>
      <div
        className={cn(styles.hidden, {
          [styles.showTabs]: isContent && hasContent,
        })}
      >
        {contentTabList.map(({ name, url }) => (
          <Tab key={name} name={name} url={url} pageAddress={pageAddress} />
        ))}
      </div>
      {showEmptyPage && <EmptyPage store={store} navTabType={emptyPageType} />}
    </>
  );
};

export default TabsNavigation;
