import cn from 'classnames';
// Types
import { TabOptions } from '../TabsNavigation';
// UI 2
import LinkButton from 'ui2/LinkButton/LinkButton';
// Styles
import styles from './Tab.module.scss';

type TabProps = TabOptions & {
  pageAddress: string;
};

const Tab = ({ name, url, pageAddress }: TabProps) => {
  const isSelected =
    name !== 'media feed'
      ? name === pageAddress || pageAddress === '[streamSlug]'
      : pageAddress === 'media' || pageAddress === '[mediaPostSlug]';

  return (
    <div className={styles.tabWrapper}>
      <LinkButton
        href={url}
        color="harvest-gold"
        className={cn(styles.tab, { [styles.highlightedTab]: isSelected })}
      >
        {name}
      </LinkButton>
    </div>
  );
};

export default Tab;
