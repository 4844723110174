import { gql } from '@apollo/client';

export const FOLLOW_STORE = gql`
  mutation FollowStores($input: FollowStoresInput!) {
    followStores(input: $input) {
      isFollowing
    }
  }
`;

export const UNFOLLOW_STORE = gql`
  mutation UnfollowStores($input: UnfollowStoresInput!) {
    unfollowStores(input: $input) {
      isFollowing
    }
  }
`;
