import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
// Helpers
import { parseForInnerHTML } from 'helpers/textEditor';
// Types
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
// Ui
import Image from 'ui/Image/Image';
// Ui2
import HashTags from 'ui2/HashTags/HashTags';
import EllipsisText from 'ui2/EllipsisText/EllipsisText';
// Components
import TopSection from 'components/common2/TopSection/TopSection';
import AthleteMenu from 'components/Athlete/Description/components/AthleteMenu/AthleteMenu';
import TabsNavigation from '../TabsNavigation/TabsNavigation';
// Styles
import styles from './Description.module.scss';

export type MenuOption = {
  label: string;
  href: string;
};

type DescriptionProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  pending?: boolean;
};

const Description = ({ store, pending = false }: DescriptionProps) => {
  const { hashtags, storeDetails } = store;
  const [showMore, setShowMore] = useState<boolean>(false);

  const storeName = storeDetails?.storeName || '';
  const isLongerName = storeName.length > 20;
  const isLongestName = storeName.length >= 30;

  const avatarUrl = storeDetails?.avatarURL || '';

  const parsedCaption = parseForInnerHTML(storeDetails?.description || '');
  const parsedBio = parseForInnerHTML(storeDetails?.bio || '');
  const bioText = parsedCaption || parsedBio || '';

  const handleSetShowMore = (value: boolean) => setShowMore(value);

  return (
    <TopSection className={styles.topSection}>
      <div className={styles.root}>
        <div className={styles.imageAndContentWrapper}>
          <div className={styles.imageWrapper}>
            <Image
              className={styles.image}
              src={avatarUrl}
              alt={storeName}
              sizes="20vw"
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.nameAndBio}>
              <div className={styles.nameWrapper}>
                <p
                  className={cn(styles.athleteName, {
                    [styles.longerName]: isLongerName,
                    [styles.longestName]: isLongestName,
                  })}
                  title={storeName}
                >
                  {storeName}
                </p>
                {!isMobile && (
                  <div className={styles.desktopFollowAndBook}>
                    <AthleteMenu store={store} size="xSmall" />
                  </div>
                )}
              </div>
              <EllipsisText
                text={bioText}
                className={styles.description}
                maxLine={{ xs: 2, ml: 3 }}
                isGold
                setShowMore={handleSetShowMore}
              />
            </div>
            <div className={styles.hashtags}>
              <HashTags
                tags={hashtags}
                className={cn({
                  [styles.tagsListColumn]: showMore,
                })}
              />
            </div>
            {!isMobile && <TabsNavigation store={store} pending={pending} />}
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={styles.mobileFollowAndBook}>
          <AthleteMenu store={store} size="xSmall" />
          <TabsNavigation store={store} pending={pending} />
        </div>
      )}
    </TopSection>
  );
};

export default Description;
