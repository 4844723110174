import { useEffect } from 'react';
import styles from './HubSpotForm.module.scss';

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.hbspt) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: '19508085',
          formId: '786f08db-71e4-432f-b88b-4f6b61f3509f',
          region: 'na1',
          target: '#hubspotForm',
          css: '',
        });
      }
    });
  }, []);

  return (
    <div className={styles.root}>
      <div id="hubspotForm" className="book-me-form"></div>
    </div>
  );
};

export default HubSpotForm;
