import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
} from '@apollo/client';
// Api
import { GET_STREAMS } from 'api/streams/queries';
// Types
import {
  StreamStatus,
  StreamOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
import { GetStreams, GetStreamsVariables } from 'api/streams/types/GetStreams';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
};

type UseGetStreamsOptions = {
  options?: BasicQueryOptions;
  variables?: GetStreamsVariables;
  skip?: boolean;
};

export const useGetStreams = (
  options?: UseGetStreamsOptions
): QueryResult<GetStreams, GetStreamsVariables> => {
  const data = useQuery<GetStreams, GetStreamsVariables>(GET_STREAMS, {
    variables: {
      input: {
        limit: options?.variables?.input?.limit || 10,
        streamStatus: [
          StreamStatus.Active,
          StreamStatus.Paused,
          StreamStatus.Interrupted,
          StreamStatus.Scheduled,
          StreamStatus.Ended,
        ],
        orderBy: StreamOrderBy.ScheduleDate,
        direction: SortDirection.ASC,
        ...options?.variables?.input,
      },
      ...options?.variables,
    },
    skip: options?.skip,
    fetchPolicy: 'cache-and-network',
    ...options?.options,
  });

  return data;
};
